import { createContext, useReducer, useEffect } from "react";
import Reducer from "./Reducer";
import axios from "axios";
import { EnableAccessibleMode } from "./Actions";

const INITIAL_STATE = {
  // user:
  //   localStorage.getItem("user") !== "undefined"
  //     ? JSON.parse(localStorage.getItem("user"))
  //     : null,
  user: null,
  isAuthenticated: false,
  isFetching: false,
  error: false,
  isAccessibleMode:
    JSON.parse(localStorage.getItem("isAccessibleMode")) || false,
  fontMultiplier: parseFloat(localStorage.getItem("fontMultiplier")) || 1,
  contrast: localStorage.getItem("contrast") || "default",
  letterSpacing: localStorage.getItem("letterSpacing") || "normal",
  typeface: localStorage.getItem("typeface") || "sans-serif",
  imageMode: localStorage.getItem("imageMode") || "enabled",
  isAnimationsEnabled:
    localStorage.getItem("isAnimationsEnabled") === "false" ? false : true,
};

export const Context = createContext({
  ...INITIAL_STATE,
});

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  const getUserInfo = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      axios.defaults.headers.common["x-auth-token"] = token;
      try {
        let res;
        if (process.env.REACT_APP_MODE === "DEV") {
          res = await axios.get("/auth/info");
        } else if (process.env.REACT_APP_MODE === "PROD") {
          res = await axios.get("/api/auth/info");
        }

        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            ...res.data.user,
          },
        });
      } catch (err) {
        console.error(err);
        localStorage.removeItem("token");
      }
    } else {
      delete axios.defaults.headers.common["x-auth-token"];
    }
  };

  useEffect(() => {
    const checkUser = async () => {
      if (!state.user) {
        await getUserInfo();
      }
    };
    checkUser();
  }, [state.user]);

  useEffect(() => {
    const accessibleMode = localStorage.getItem("isAccessibleMode") === "true";
    if (accessibleMode) {
      dispatch(EnableAccessibleMode());
    }
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("isAccessibleMode", state.isAccessibleMode);
  // }, [state.isAccessibleMode]);

  useEffect(() => {
    const settings = {
      isAccessibleMode: state.isAccessibleMode,
      fontMultiplier: state.fontMultiplier,
      contrast: state.contrast,
      letterSpacing: state.letterSpacing,
      typeface: state.typeface,
      imageMode: state.imageMode,
      isAnimationsEnabled: state.isAnimationsEnabled,
    };
    Object.keys(settings).forEach((key) => {
      localStorage.setItem(key, settings[key]);
    });
  }, [state]);

  return (
    <Context.Provider
      value={{
        ...state,
        getUserInfo,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
};
