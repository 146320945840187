import { useContext, useState, useEffect, useCallback } from "react";
import { Context } from "../../context/Context";
import {
  EnableAccessibleMode,
  DisableAccessibleMode,
} from "../../context/Actions";
import style from "./AccessibleModeToggle.module.scss";
import { ReactComponent as AccessibilitySVG } from "../../assets/imgs/accessibility.svg";
import { useLocation } from "react-router-dom";

const AccessibleModeToggle = () => {
  const { isAccessibleMode, dispatch, contrast } = useContext(Context);
  const [isOnHome, setOnHome] = useState(false);
  const location = useLocation();

  const handleToggle = useCallback(() => {
    dispatch(
      isAccessibleMode ? DisableAccessibleMode() : EnableAccessibleMode()
    );
  }, [dispatch, isAccessibleMode]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      handleToggle();
    }
  };

  useEffect(() => {
    setOnHome(location.pathname === "/");
  }, [location.pathname]);

  return (
    <button
      onClick={handleToggle}
      onKeyDown={handleKeyDown}
      className={`${style.button} ${
        contrast === "default"
          ? style.contrastDefault
          : contrast === "light"
          ? style.contrastWhite
          : contrast === "dark"
          ? style.contrastDark
          : contrast === "blue"
          ? style.contrastBlue
          : ""
      } ${isAccessibleMode ? style.modeActive : ""} ${
        isOnHome ? style.onHome : ""
      }`}
      title={
        isAccessibleMode
          ? "Отключить версию для слабовидящих"
          : "Включить версию для слабовидящих"
      }
      aria-label={
        isAccessibleMode
          ? "Отключить версию для слабовидящих"
          : "Включить версию для слабовидящих"
      }
      aria-pressed={isAccessibleMode}
    >
      <AccessibilitySVG />
    </button>
  );
};

export default AccessibleModeToggle;
