import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./Header.module.scss";
import { ReactComponent as LogoSVG } from "../../assets/imgs/logo.svg";
import { ReactComponent as NewYearLogoSVG } from "../../assets/imgs/newyearlogo.svg";
import { ReactComponent as AddSVG } from "../../assets/imgs/add.svg";
import { ReactComponent as EditSVG } from "../../assets/imgs/write.svg";
import { ReactComponent as SettingsSVG } from "../../assets/imgs/settings.svg";
import { ReactComponent as LogoutSVG } from "../../assets/imgs/logout.svg";
import { ReactComponent as HamburgerSVG } from "../../assets/imgs/hamburger.svg";
import { Context } from "../../context/Context";
import { ReactComponent as BackSVG } from "../../assets/imgs/back.svg";
import { useLocation } from "react-router-dom";
import useAuth from "../../assets/hooks/useAuth";
import AccessibleModeToggle from "../AccessibleModeToggle/AccessibleModeToggle";
import { useMediaQuery } from "react-responsive";
import {
  EnableAccessibleMode,
  DisableAccessibleMode,
} from "../../context/Actions";

const Header = ({ noticesData, isSnowflakeSeason }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isOnHome, setOnHome] = useState();

  const location = useLocation();

  const { user, dispatch, imageMode, contrast, isAccessibleMode } =
    useContext(Context);

  const { isAuthenticated } = useAuth();

  const disableBodyScroll = () => {
    document.body.classList.add("disable-scroll");
  };
  const enableBodyScroll = () => {
    document.body.classList.remove("disable-scroll");
  };

  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 480 });

  const toggleNavMenu = () => {
    setIsNavExpanded(!isNavExpanded);
    if (isAccessibleMode) {
      dispatch(DisableAccessibleMode());
    }

    if (!isNavExpanded) {
      disableBodyScroll();
      // Программный перевод фокуса
      setTimeout(() => {
        const firstMenuItem = document.querySelector('[role="menuitem"]');
        if (firstMenuItem) firstMenuItem.focus();
      }, 100);
    } else {
      enableBodyScroll();
    }
  };

  const handleMenuAction = (action) => {
    if (isNavExpanded) toggleNavMenu();
    action();
  };

  const handleKeyDown = (e, action) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleMenuAction(action);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    window.location.replace("/login");
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setOnHome(true);
    } else {
      setOnHome(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={`${style.headerWrapper} ${
          contrast === "default"
            ? style.contrastDefault
            : contrast === "light"
            ? style.contrastWhite
            : contrast === "dark"
            ? style.contrastDark
            : contrast === "blue"
            ? style.contrastBlue
            : ""
        } ${imageMode === "disabled" ? style.imageModeDisabled : ""}`}
      >
        <div className={style.header}>
          <nav className={style.navigation} aria-label="Основное меню">
            <Link
              to="/"
              className={style.logo}
              onClick={() => {
                if (isNavExpanded) toggleNavMenu();
              }}
            >
              {imageMode === "enabled" &&
                (isSnowflakeSeason ? <NewYearLogoSVG /> : <LogoSVG />)}
              {imageMode === "black-white" &&
                (isSnowflakeSeason ? (
                  <NewYearLogoSVG className={style.blackWhite} />
                ) : (
                  <LogoSVG className={style.blackWhite} />
                ))}{" "}
              <span className={style.title}>
                {!isAuthenticated
                  ? "Нижегородское метро"
                  : `Пользователь: ${user.username} [${user.department}]`}
              </span>
            </Link>
            {((isOnHome && !isAuthenticated) || isAuthenticated) && (
              <button
                className={style.hamburger}
                onClick={toggleNavMenu}
                aria-expanded={isNavExpanded}
                aria-label="Открыть меню навигации"
                tabIndex={isMobile ? 0 : -1}
              >
                <HamburgerSVG />
              </button>
            )}

            <div
              className={
                isNavExpanded
                  ? `${style.navigationMenu} ${style.expanded}`
                  : style.navigationMenu
              }
              aria-label="Основное меню"
            >
              {isOnHome ? (
                <>
                  {!isAuthenticated ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <ul role="menu">
                        <li
                          tabIndex="0"
                          role="menuitem"
                          aria-current={
                            location.pathname === "/" ? "page" : undefined
                          }
                          onClick={() =>
                            handleMenuAction(() =>
                              window.scrollTo({ top: 0, behavior: "smooth" })
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(e, () =>
                              window.scrollTo({ top: 0, behavior: "smooth" })
                            )
                          }
                        >
                          <span>Главная</span>
                        </li>
                        {noticesData?.length > 0 && (
                          <li
                            tabIndex="0"
                            role="menuitem"
                            onClick={(e) => {
                              e.preventDefault();
                              if (isNavExpanded) toggleNavMenu();
                              const elementToView =
                                document.getElementById("notices");
                              elementToView.scrollIntoView({
                                behavior: "smooth",
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                e.preventDefault();
                                if (isNavExpanded) toggleNavMenu();
                                const elementToView =
                                  document.getElementById("notices");
                                elementToView.scrollIntoView({
                                  behavior: "smooth",
                                });
                              }
                            }}
                          >
                            <span>Объявления</span>
                          </li>
                        )}

                        <li
                          tabIndex="0"
                          role="menuitem"
                          onClick={(e) => {
                            e.preventDefault();
                            if (isNavExpanded) toggleNavMenu();
                            const elementToView =
                              document.getElementById("info");
                            elementToView.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              if (isNavExpanded) toggleNavMenu();
                              const elementToView =
                                document.getElementById("info");
                              elementToView.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }}
                        >
                          <span>Полезная информация</span>
                        </li>
                        <li
                          tabIndex="0"
                          role="menuitem"
                          onClick={(e) => {
                            e.preventDefault();
                            if (isNavExpanded) toggleNavMenu();
                            const elementToView =
                              document.getElementById("news");
                            elementToView.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              if (isNavExpanded) toggleNavMenu();
                              const elementToView =
                                document.getElementById("news");
                              elementToView.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }}
                        >
                          <span>Новости</span>
                        </li>
                        <li
                          tabIndex="0"
                          role="menuitem"
                          onClick={(e) => {
                            e.preventDefault();
                            if (isNavExpanded) toggleNavMenu();
                            const elementToView =
                              document.getElementById("contacts");
                            elementToView.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              if (isNavExpanded) toggleNavMenu();
                              const elementToView =
                                document.getElementById("contacts");
                              elementToView.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }}
                        >
                          <span>Контакты</span>
                        </li>
                      </ul>
                      <button
                        className={style.accessibleModeToggle}
                        onClick={() => {
                          if (isNavExpanded) toggleNavMenu();
                        }}
                        tabIndex="-1"
                      >
                        <AccessibleModeToggle />
                      </button>
                    </div>
                  ) : (
                    <ul className={style.adminMenu} role="menu">
                      {(user.department === "ВЦ" ||
                        user.department === "А") && (
                        <li
                          className={style.adminButton}
                          role="menuitem"
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="write-notice">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новое объявление
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А") && (
                        <li
                          className={style.adminButton}
                          role="menuitem"
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/news/write">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новая новость
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "УП") && (
                        <li
                          className={style.adminButton}
                          role="menuitem"
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/vacancies/new">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новая вакансия
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "ОТ") && (
                        <li
                          className={style.adminButton}
                          role="menuitem"
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/labor-protection/new">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>Новая СОУТ</span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "УП") && (
                        <li
                          className={style.adminButton}
                          role="menuitem"
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/training-center">
                            <EditSVG />{" "}
                            <span className={style.adminAdd}>
                              Учебный центр
                            </span>
                          </Link>
                        </li>
                      )}
                      <li
                        className={style.adminButton}
                        role="menuitem"
                        onClick={() => {
                          if (isNavExpanded) toggleNavMenu();
                        }}
                      >
                        <Link to="/settings">
                          <SettingsSVG />{" "}
                          <span className={style.adminTxt}>Настройки</span>
                        </Link>
                      </li>
                      <li
                        className={style.adminButton}
                        role="menuitem"
                        onClick={() => {
                          handleLogout();
                          if (isNavExpanded) toggleNavMenu();
                        }}
                      >
                        <div className={style.logout}>
                          <LogoutSVG />{" "}
                          <span className={style.adminTxt}>Выйти</span>
                        </div>
                      </li>
                    </ul>
                  )}
                </>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={style.backBtn}>
                    <Link to="/" tabIndex="0">
                      <BackSVG /> <span>на главную</span>
                    </Link>
                  </div>

                  <button
                    className={style.accessibleModeToggle}
                    onClick={() => {
                      if (isNavExpanded) toggleNavMenu();
                    }}
                    tabIndex="-1"
                    aria-label="Переключить режим доступности"
                  >
                    <AccessibleModeToggle />
                  </button>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
