import React, { useState, useEffect, useContext } from "react";
import style from "./ScrollToTopButton.module.scss";
import { ReactComponent as ToTopSVG } from "../../assets/imgs/toTop.svg";
import { Context } from "../../context/Context";

const ScrollToTopButton = () => {
  const { contrast } = useContext(Context);

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      scrollToTop();
    }
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 400) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div
      className={`${style.toTop} ${
        contrast === "default"
          ? style.contrastDefault
          : contrast === "light"
          ? style.contrastWhite
          : contrast === "dark"
          ? style.contrastDark
          : contrast === "blue"
          ? style.contrastBlue
          : ""
      }`}
    >
      {isVisible ? (
        <div
          onClick={scrollToTop}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          role="button"
          aria-label="Вернуться наверх страницы"
        >
          <ToTopSVG />
        </div>
      ) : null}
    </div>
  );
};

export default ScrollToTopButton;
