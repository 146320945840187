import React, { useContext } from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.scss";
import { Context } from "../../context/Context";

const Footer = () => {
  const date = new Date();
  const { contrast } = useContext(Context);

  return (
    <div
      className={`${style.footerWrapper} ${
        contrast === "default"
          ? style.contrastDefault
          : contrast === "light"
          ? style.contrastWhite
          : contrast === "dark"
          ? style.contrastDark
          : contrast === "blue"
          ? style.contrastBlue
          : ""
      }`}
      aria-live="polite"
    >
      <div className={style.footer}>
        <p>
          <Link
            to="/privacy"
            className={style.privacy}
            aria-label="Политика в отношении обработки персональных данных"
          >
            Политика в отношении обработки персональных данных
          </Link>
        </p>
        <p className={style.copyright}>
          МП «Нижегородское метро» {date.getFullYear()} © Разработка:{" "}
          <a
            href="https://mithra.ru"
            target="_blank"
            rel="noreferrer"
            aria-label="Сайт разработчика Mithra (внешняя ссылка)"
          >
            Mithra
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
