const Reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        user: null,
        isFetching: true,
        error: false,
        isAuthenticated: false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: false,
        isAuthenticated: true,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        user: null,
        isFetching: false,
        error: true,
        isAuthenticated: false,
      };

    case "LOGOUT":
      return {
        ...state,
        user: null,
        isFetching: false,
        error: false,
        isAuthenticated: false,
      };

    case "PUBLISH_START":
      return {
        ...state,
        user: action.payload,
        isFetching: true,
        error: false,
      };
    case "PUBLISH_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: false,
      };
    case "PUBLISH_FAILURE":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: true,
      };

    case "DELETE_START":
      return {
        ...state,
        user: action.payload,
        isFetching: true,
        error: false,
      };
    case "DELETE_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: false,
      };
    case "DELETE_FAILURE":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: true,
      };

    case "UPDATE_START":
      return {
        ...state,
        isFetching: true,
      };
    case "UPDATE_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: false,
      };
    case "UPDATE_FAILURE":
      return {
        ...state,
        user: state.user,
        isFetching: false,
        error: true,
      };

    case "FETCH_START":
      return {
        ...state,
        user: action.payload,
        isFetching: true,
        error: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: false,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: true,
      };

    case "ENABLE_ACCESSIBLE_MODE":
      return {
        ...state,
        isAccessibleMode: true,
      };
    case "DISABLE_ACCESSIBLE_MODE":
      return {
        ...state,
        isAccessibleMode: false,
      };

    case "SET_FONT_MULTIPLIER":
      return {
        ...state,
        fontMultiplier: action.payload,
      };
    case "SET_CONTRAST":
      return {
        ...state,
        contrast: action.payload,
      };
    case "SET_LETTER_SPACING":
      return {
        ...state,
        letterSpacing: action.payload,
      };
    case "SET_TYPEFACE":
      return {
        ...state,
        typeface: action.payload,
      };
    case "SET_IMAGE_MODE":
      return {
        ...state,
        imageMode: action.payload,
      };
    case "TOGGLE_ANIMATIONS":
      return {
        ...state,
        isAnimationsEnabled: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
