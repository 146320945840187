import React, { useState, useEffect, useContext } from "react";
import style from "./Preloader.module.scss";
import { ReactComponent as LogoSVG } from "../../assets/imgs/logoBig.svg";
import { ReactComponent as NewYearLogoSVG } from "../../assets/imgs/newyearlogobig.svg";
import { Context } from "../../context/Context";

const Preloader = () => {
  const [isSnowflakeSeason, setIsSnowflakeSeason] = useState(false);

  const { contrast, imageMode } = useContext(Context);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const noFlakesSeasonStart = new Date(`${currentYear}-01-08`);
    const noFlakesSeasonEnd = new Date(`${currentYear}-12-25`);

    const isSeason =
      currentDate >= noFlakesSeasonStart && currentDate <= noFlakesSeasonEnd;

    setIsSnowflakeSeason(!isSeason);
  }, []);

  return (
    <div
      className={`${style.preloader} ${
        contrast === "default"
          ? style.contrastDefault
          : contrast === "light"
          ? style.contrastWhite
          : contrast === "dark"
          ? style.contrastDark
          : contrast === "blue"
          ? style.contrastBlue
          : ""
      } ${imageMode === "black-white" ? style.imageModeBW : ""}`}
    >
      <div className={style.logo}>
        {isSnowflakeSeason ? <NewYearLogoSVG /> : <LogoSVG />}
      </div>
    </div>
  );
};

export default Preloader;
