import { useContext, useState, useEffect } from "react";
import { Context } from "../../context/Context";
import style from "./AccessibleModePanel.module.scss";
import { useMediaQuery } from "react-responsive";
import { DisableAccessibleMode } from "../../context/Actions";

const AccessibleModePanel = () => {
  const [activeButtons, setActiveButtons] = useState({
    fontMultiplier: 1, // Default font size
    contrast: "default", // Default contrast
    letterSpacing: "normal", // Default letter spacing
    typeface: "sans-serif", // Default typeface
    imageMode: "enabled", // Default image mode
  });

  const {
    dispatch,
    isAccessibleMode,
    contrast,
    fontMultiplier,
    letterSpacing,
    typeface,
    imageMode,
  } = useContext(Context);

  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 480 });

  // Initialize active buttons state based on stored settings
  useEffect(() => {
    setActiveButtons({
      fontMultiplier: fontMultiplier || 1,
      contrast: contrast || "default",
      letterSpacing: letterSpacing || "normal",
      typeface: typeface || "sans-serif",
      imageMode: imageMode || "enabled",
    });
  }, [fontMultiplier, contrast, letterSpacing, typeface, imageMode]);

  useEffect(() => {
    if (isAccessibleMode) {
      document.documentElement.setAttribute("data-accessible", "true");
    } else {
      document.documentElement.setAttribute("data-accessible", "false");
    }
  }, [isAccessibleMode]);

  const handleFontMultiplierChange = (multiplier) => {
    if (activeButtons.fontMultiplier === multiplier) {
      // If multiplier matches the default, keep it active
      if (multiplier === 1) return;

      document.documentElement.style.setProperty("--font-multiplier", 1);
      dispatch({ type: "SET_FONT_MULTIPLIER", payload: 1 });
      setActiveButtons((prev) => ({ ...prev, fontMultiplier: 1 }));
    } else {
      document.documentElement.style.setProperty(
        "--font-multiplier",
        multiplier
      );
      dispatch({ type: "SET_FONT_MULTIPLIER", payload: multiplier });
      setActiveButtons((prev) => ({ ...prev, fontMultiplier: multiplier }));
    }
  };

  const handleContrastChange = (theme) => {
    if (activeButtons.contrast === theme) {
      // If contrast matches the default, keep it active
      if (theme === "default") return;

      document.documentElement.setAttribute("data-theme", "default");
      dispatch({ type: "SET_CONTRAST", payload: "default" });
      setActiveButtons((prev) => ({ ...prev, contrast: "default" }));
    } else {
      document.documentElement.setAttribute("data-theme", theme);
      dispatch({ type: "SET_CONTRAST", payload: theme });
      setActiveButtons((prev) => ({ ...prev, contrast: theme }));
    }
  };

  const handleLetterSpacingChange = (spacing) => {
    if (activeButtons.letterSpacing === spacing) {
      // If letter spacing matches the default, keep it active
      if (spacing === "normal") return;

      document.documentElement.style.setProperty("--letter-spacing", "normal");
      dispatch({ type: "SET_LETTER_SPACING", payload: "normal" });
      setActiveButtons((prev) => ({ ...prev, letterSpacing: "normal" }));
    } else {
      document.documentElement.style.setProperty("--letter-spacing", spacing);
      dispatch({ type: "SET_LETTER_SPACING", payload: spacing });
      setActiveButtons((prev) => ({ ...prev, letterSpacing: spacing }));
    }
  };

  const handleTypefaceChange = (typeface) => {
    if (activeButtons.typeface === typeface) {
      // If typeface matches the default, keep it active
      if (typeface === "sans-serif") return;

      document.documentElement.style.setProperty(
        "--typeface",
        "Roboto Condensed"
      );
      dispatch({ type: "SET_TYPEFACE", payload: "sans-serif" });
      setActiveButtons((prev) => ({ ...prev, typeface: "sans-serif" }));
    } else {
      const fontFamily =
        typeface === "serif" ? "Times New Roman" : "Roboto Condensed";
      document.documentElement.style.setProperty("--typeface", fontFamily);
      dispatch({ type: "SET_TYPEFACE", payload: typeface });
      setActiveButtons((prev) => ({ ...prev, typeface }));
    }
  };

  const handleImageModeChange = (mode) => {
    if (activeButtons.imageMode === mode) {
      // If image mode matches the default, keep it active
      if (mode === "enabled") return;

      document.body.classList.remove("images-disabled", "images-black-white");
      dispatch({ type: "SET_IMAGE_MODE", payload: "enabled" });
      setActiveButtons((prev) => ({ ...prev, imageMode: "enabled" }));
    } else {
      document.body.classList.remove("images-disabled", "images-black-white");
      if (mode === "disabled") document.body.classList.add("images-disabled");
      if (mode === "black-white")
        document.body.classList.add("images-black-white");
      dispatch({ type: "SET_IMAGE_MODE", payload: mode });
      setActiveButtons((prev) => ({ ...prev, imageMode: mode }));
    }
  };

  const handleResetSettings = () => {
    handleFontMultiplierChange(1);
    handleContrastChange("default");
    handleLetterSpacingChange("normal");
    handleTypefaceChange("sans-serif");
    handleImageModeChange("enabled");

    setActiveButtons({
      fontMultiplier: 1,
      contrast: "default",
      letterSpacing: "normal",
      typeface: "sans-serif",
      imageMode: "enabled",
    });
  };

  const handleClosePanel = () => {
    dispatch(DisableAccessibleMode());
  };

  return (
    <div
      className={`${style.panelWrapper} ${
        isAccessibleMode ? style.visible : style.hidden
      } ${
        contrast === "default"
          ? style.contrastDefault
          : contrast === "light"
          ? style.contrastWhite
          : contrast === "dark"
          ? style.contrastDark
          : contrast === "blue"
          ? style.contrastBlue
          : ""
      }`}
      role="dialog"
      aria-labelledby="accessible-mode-panel"
      aria-modal="true"
      aria-live="polite"
    >
      <div className={style.panel} id="accessible-mode-panel">
        <div className={style.line}>
          {/* Font Size */}
          {isMobile ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <div
                className={style.fontMultiplier}
                aria-labelledby="font-size-title"
              >
                <span id="font-size-title">Размер шрифта</span>
                <div
                  className={style.control}
                  role="group"
                  aria-label="Выбор размера шрифта"
                >
                  <button
                    onClick={() => handleFontMultiplierChange(1)}
                    className={
                      activeButtons.fontMultiplier === 1 ? style.active : ""
                    }
                    tabIndex={isAccessibleMode ? 0 : -1}
                    aria-label="Размер шрифта обычный"
                    aria-pressed={activeButtons.fontMultiplier === 1}
                    aria-hidden={isAccessibleMode ? "false" : "true"}
                  >
                    A
                  </button>
                  <button
                    onClick={() => handleFontMultiplierChange(1.18)}
                    className={
                      activeButtons.fontMultiplier === 1.18 ? style.active : ""
                    }
                    tabIndex={isAccessibleMode ? 0 : -1}
                    aria-label="Размер шрифта увеличенный"
                    aria-pressed={activeButtons.fontMultiplier === 1.18}
                    aria-hidden={isAccessibleMode ? "false" : "true"}
                  >
                    A
                  </button>
                  <button
                    onClick={() => handleFontMultiplierChange(1.27)}
                    className={
                      activeButtons.fontMultiplier === 1.27 ? style.active : ""
                    }
                    tabIndex={isAccessibleMode ? 0 : -1}
                    aria-label="Размер шрифта большой"
                    aria-pressed={activeButtons.fontMultiplier === 1.27}
                    aria-hidden={isAccessibleMode ? "false" : "true"}
                  >
                    A
                  </button>
                </div>
              </div>

              {/* Contrast */}

              <div className={style.contrast} aria-labelledby="contrast-title">
                <span id="contrast-title">Цвет</span>

                <div
                  className={style.control}
                  role="group"
                  aria-label="Выбор контраста"
                >
                  <button
                    onClick={() => handleContrastChange("light")}
                    className={
                      activeButtons.contrast === "light" ? style.active : ""
                    }
                    tabIndex={isAccessibleMode ? 0 : -1}
                    aria-label="Светлый контраст"
                    aria-pressed={activeButtons.contrast === "light"}
                    aria-hidden={isAccessibleMode ? "false" : "true"}
                  >
                    A
                  </button>
                  <button
                    onClick={() => handleContrastChange("dark")}
                    className={
                      activeButtons.contrast === "dark" ? style.active : ""
                    }
                    tabIndex={isAccessibleMode ? 0 : -1}
                    aria-label="Темный контраст"
                    aria-pressed={activeButtons.contrast === "dark"}
                    aria-hidden={isAccessibleMode ? "false" : "true"}
                  >
                    A
                  </button>
                  <button
                    onClick={() => handleContrastChange("blue")}
                    className={
                      activeButtons.contrast === "blue" ? style.active : ""
                    }
                    tabIndex={isAccessibleMode ? 0 : -1}
                    aria-label="Голубой контраст"
                    aria-pressed={activeButtons.contrast === "blue"}
                    aria-hidden={isAccessibleMode ? "false" : "true"}
                  >
                    A
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={style.fontMultiplier}
              aria-labelledby="font-size-title"
            >
              <span id="font-size-title">Размер шрифта</span>
              <div
                className={style.control}
                role="group"
                aria-label="Выбор размера шрифта"
              >
                <button
                  onClick={() => handleFontMultiplierChange(1)}
                  className={
                    activeButtons.fontMultiplier === 1 ? style.active : ""
                  }
                  tabIndex={isAccessibleMode ? 0 : -1}
                  aria-label="Размер шрифта обычный"
                  aria-pressed={activeButtons.fontMultiplier === 1}
                  aria-hidden={isAccessibleMode ? "false" : "true"}
                >
                  A
                </button>
                <button
                  onClick={() => handleFontMultiplierChange(1.18)}
                  className={
                    activeButtons.fontMultiplier === 1.18 ? style.active : ""
                  }
                  tabIndex={isAccessibleMode ? 0 : -1}
                  aria-label="Размер шрифта увеличенный"
                  aria-pressed={activeButtons.fontMultiplier === 1.18}
                  aria-hidden={isAccessibleMode ? "false" : "true"}
                >
                  A
                </button>
                <button
                  onClick={() => handleFontMultiplierChange(1.27)}
                  className={
                    activeButtons.fontMultiplier === 1.27 ? style.active : ""
                  }
                  tabIndex={isAccessibleMode ? 0 : -1}
                  aria-label="Размер шрифта большой"
                  aria-pressed={activeButtons.fontMultiplier === 1.27}
                  aria-hidden={isAccessibleMode ? "false" : "true"}
                >
                  A
                </button>
              </div>
            </div>
          )}

          {/* Интервал */}
          <div
            className={style.interval}
            aria-labelledby="letter-spacing-title"
          >
            <span id="letter-spacing-title">Интервал</span>

            <div
              className={style.control}
              role="group"
              aria-label="Выбор интервала"
            >
              <button
                onClick={() => handleLetterSpacingChange("normal")}
                className={
                  activeButtons.letterSpacing === "normal" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Нормальный интервал"
                aria-pressed={activeButtons.letterSpacing === "normal"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Нормальный
              </button>
              <button
                onClick={() => handleLetterSpacingChange("0.075em")}
                className={
                  activeButtons.letterSpacing === "0.075em" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Увеличенный интервал"
                aria-pressed={activeButtons.letterSpacing === "0.075em"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Увеличенный
              </button>
              <button
                onClick={() => handleLetterSpacingChange("0.125em")}
                className={
                  activeButtons.letterSpacing === "0.125em" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Большой интервал"
                aria-pressed={activeButtons.letterSpacing === "0.125em"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Большой
              </button>
            </div>
          </div>

          {/* Typeface */}
          <div className={style.fontStyle} aria-labelledby="typeface-title">
            <span id="typeface-title">Гарнитура</span>

            <div
              className={style.control}
              role="group"
              aria-label="Выбор гарнитуры"
            >
              <button
                onClick={() => handleTypefaceChange("sans-serif")}
                className={
                  activeButtons.typeface === "sans-serif" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Гарнитура без засечек"
                aria-pressed={activeButtons.typeface === "sans-serif"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Без засечек
              </button>
              <button
                onClick={() => handleTypefaceChange("serif")}
                className={
                  activeButtons.typeface === "serif" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Гарнитура с засечками"
                aria-pressed={activeButtons.typeface === "serif"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                С засечками
              </button>
            </div>
          </div>
        </div>

        <div className={style.line}>
          {/* Contrast */}
          {!isMobile && (
            <div className={style.contrast} aria-labelledby="contrast-title">
              <span id="contrast-title">Цвет</span>

              <div
                className={style.control}
                role="group"
                aria-label="Выбор контраста"
              >
                <button
                  onClick={() => handleContrastChange("light")}
                  className={
                    activeButtons.contrast === "light" ? style.active : ""
                  }
                  tabIndex={isAccessibleMode ? 0 : -1}
                  aria-label="Светлый контраст"
                  aria-pressed={activeButtons.contrast === "light"}
                  aria-hidden={isAccessibleMode ? "false" : "true"}
                >
                  A
                </button>
                <button
                  onClick={() => handleContrastChange("dark")}
                  className={
                    activeButtons.contrast === "dark" ? style.active : ""
                  }
                  tabIndex={isAccessibleMode ? 0 : -1}
                  aria-label="Темный контраст"
                  aria-pressed={activeButtons.contrast === "dark"}
                  aria-hidden={isAccessibleMode ? "false" : "true"}
                >
                  A
                </button>
                <button
                  onClick={() => handleContrastChange("blue")}
                  className={
                    activeButtons.contrast === "blue" ? style.active : ""
                  }
                  tabIndex={isAccessibleMode ? 0 : -1}
                  aria-label="Голубой контраст"
                  aria-pressed={activeButtons.contrast === "blue"}
                  aria-hidden={isAccessibleMode ? "false" : "true"}
                >
                  A
                </button>
              </div>
            </div>
          )}

          {/* Images */}
          <div className={style.images} aria-labelledby="images-title">
            <span id="images-title">Изображения</span>

            <div
              className={style.control}
              role="group"
              aria-label="Режим изображений"
            >
              <button
                onClick={() => handleImageModeChange("enabled")}
                className={
                  activeButtons.imageMode === "enabled" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Изображения включены"
                aria-pressed={activeButtons.imageMode === "enabled"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Включены
              </button>
              <button
                onClick={() => handleImageModeChange("disabled")}
                className={
                  activeButtons.imageMode === "disabled" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Изображения выключены"
                aria-pressed={activeButtons.imageMode === "disabled"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Выключены
              </button>
              <button
                onClick={() => handleImageModeChange("black-white")}
                className={
                  activeButtons.imageMode === "black-white" ? style.active : ""
                }
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Изображения черно-белые"
                aria-pressed={activeButtons.imageMode === "black-white"}
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Ч/Б
              </button>
            </div>
          </div>

          <div className={style.resetWrapper}>
            <div className={style.control}>
              <button
                onClick={handleResetSettings}
                className={style.resetButton}
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Сбросить настройки"
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Сброс
              </button>
              <button
                onClick={handleClosePanel}
                className={style.closeButton}
                tabIndex={isAccessibleMode ? 0 : -1}
                aria-label="Закрыть панель"
                aria-hidden={isAccessibleMode ? "false" : "true"}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessibleModePanel;
