import React, { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import style from "./App.module.scss";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import Preloader from "./components/Preloader/Preloader";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import GuestGuard from "./components/GuestGuard/GuestGuard";
import useAuth from "./assets/hooks/useAuth";
import CookiesDisclaimer from "./components/CookiesDisclaimer/CookiesDisclaimer";
import Snowflakes from "./components/Snowflakes/Snowflakes";
import SnowflakesToggle from "./components/SnowflakesToggle/SnowflakesToggle";
import AccessibleModePanel from "./components/AccessibleModePanel/AccessibleModePanel";
import { useContext } from "react";
import { Context } from "./context/Context";

const Homepage = lazy(() => {
  return Promise.all([
    import("./pages/Homepage/Homepage"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const TrainsShedule = lazy(() => {
  return Promise.all([
    import("./pages/TrainsShedule/TrainsShedule"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const StationsShedule = lazy(() => {
  return Promise.all([
    import("./pages/StationsShedule/StationsShedule"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Lost = lazy(() => {
  return Promise.all([
    import("./pages/Lost/Lost"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const History = lazy(() => {
  return Promise.all([
    import("./pages/History/History"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Rules = lazy(() => {
  return Promise.all([
    import("./pages/Rules/Rules"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const TrainingCenter = lazy(() => {
  return Promise.all([
    import("./pages/TrainingCenter/TrainingCenter"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Vacancies = lazy(() => {
  return Promise.all([
    import("./pages/Vacancies/Vacancies"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const LimitedMobility = lazy(() => {
  return Promise.all([
    import("./pages/LimitedMobility/LimitedMobility"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const LaborProtection = lazy(() => {
  return Promise.all([
    import("./pages/LaborProtection/LaborProtection"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Payment = lazy(() => {
  return Promise.all([
    import("./pages/Payment/Payment"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Museum = lazy(() => {
  return Promise.all([
    import("./pages/Museum/Museum"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Health = lazy(() => {
  return Promise.all([
    import("./pages/Health/Health"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Gripp = lazy(() => {
  return Promise.all([
    import("./pages/Gripp/Gripp"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Acquiring = lazy(() => {
  return Promise.all([
    import("./pages/Acquiring/Acquiring"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Privacy = lazy(() => {
  return Promise.all([
    import("./pages/Privacy/Privacy"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const PersonalDataConsent = lazy(() => {
  return Promise.all([
    import("./pages/PersonalDataConsent/PersonalDataConsent"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const SinglePost = lazy(() => {
  return Promise.all([
    import("./pages/SinglePost/SinglePost"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const News = lazy(() => {
  return Promise.all([
    import("./pages/News/News"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Page404 = lazy(() => {
  return Promise.all([
    import("./pages/Page404/Page404"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Director = lazy(() => {
  return Promise.all([
    import("./pages/Director/Director"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const WriteUs = lazy(() => {
  return Promise.all([
    import("./pages/WriteUs/WriteUs"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const CookiesInfo = lazy(() => {
  return Promise.all([
    import("./pages/CookiesInfo/CookiesInfo"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Login = lazy(() => {
  return Promise.all([
    import("./pages/Login/Login"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Register = lazy(() => {
  return Promise.all([
    import("./pages/Register/Register"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const WriteNews = lazy(() => {
  return Promise.all([
    import("./pages/WriteNews/WriteNews"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const WriteNotice = lazy(() => {
  return Promise.all([
    import("./pages/WriteNotice/WriteNotice"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const NewVacancy = lazy(() => {
  return Promise.all([
    import("./pages/NewVacancy/NewVacancy"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const AddSout = lazy(() => {
  return Promise.all([
    import("./pages/AddSout/AddSout"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const EditSout = lazy(() => {
  return Promise.all([
    import("./pages/EditSout/EditSout"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});
const Settings = lazy(() => {
  return Promise.all([
    import("./pages/Settings/Settings"),
    new Promise((resolve) => setTimeout(resolve, 350)),
  ]).then(([moduleExports]) => moduleExports);
});

function App(props) {
  const { isAccessibleMode, contrast } = useContext(Context);

  const { isAuthenticated } = useAuth();

  const [noticesData, setNoticesData] = useState(null);

  const [isSnowflakeSeason, setIsSnowflakeSeason] = useState(false);

  const [showSnowflakes, setShowSnowflakes] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    //  const currentDate = new Date("2025-01-01");

    const currentYear = currentDate.getFullYear();

    const noFlakesSeasonStart = new Date(`${currentYear}-01-09`);
    const noFlakesSeasonEnd = new Date(`${currentYear}-12-22`);

    const isSeason =
      currentDate >= noFlakesSeasonStart && currentDate <= noFlakesSeasonEnd;

    setIsSnowflakeSeason(!isSeason);
    setShowSnowflakes(!isSeason);
  }, []);

  const handleSnowflakeToggle = (event) => {
    setShowSnowflakes(event.target.checked); // Updates showSnowflakes state based on checkbox status
  };

  return (
    <>
      {isSnowflakeSeason && showSnowflakes && <Snowflakes />}
      <ScrollToTop />
      <div
        className={`${style.appWrapper} ${
          contrast === "default"
            ? style.contrastDefault
            : contrast === "light"
            ? style.contrastWhite
            : contrast === "dark"
            ? style.contrastDark
            : contrast === "blue"
            ? style.contrastBlue
            : ""
        }`}

        // style={{
        //   paddingTop: isAccessibleMode ? "202px" : "0",
        // }}
      >
        <div className={style.app}>
          <header className={style.header}>
            <AccessibleModePanel />
            <Header
              appProps={props}
              noticesData={noticesData}
              isSnowflakeSeason={isSnowflakeSeason}
            />
          </header>
          <Suspense fallback={<Preloader />}>
            <div className={style.mainContent}>
              <Routes preserverScrollPosition={true}>
                <Route
                  index
                  path="/"
                  element={<Homepage setNoticesData={setNoticesData} />}
                />
                <Route path="trains-shedule" element={<TrainsShedule />} />
                <Route path="stations-shedule" element={<StationsShedule />} />
                <Route path="lost" element={<Lost />} />
                <Route path="history" element={<History />} />
                <Route path="rules" element={<Rules />} />
                <Route path="training-center" element={<TrainingCenter />} />
                <Route path="limited-mobility" element={<LimitedMobility />} />

                <Route path="payment" element={<Payment />} />
                <Route path="museum" element={<Museum />} />
                <Route path="health" element={<Health />} />
                <Route path="gripp" element={<Gripp />} />
                <Route path="acquiring" element={<Acquiring />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="director" element={<Director />} />
                <Route path="write-us" element={<WriteUs />} />
                <Route path="cookies" element={<CookiesInfo />} />
                <Route
                  path="personal-data-concent"
                  element={<PersonalDataConsent />}
                />
                <Route path="news">
                  <Route index element={<News />} />
                  <Route path=":postSlug" element={<SinglePost />} />
                  <Route
                    path="write"
                    element={
                      <GuestGuard isAuthenticated={isAuthenticated}>
                        <WriteNews />
                      </GuestGuard>
                    }
                  />
                </Route>
                <Route path="vacancies">
                  <Route index element={<Vacancies />} />

                  <Route
                    path="new"
                    element={
                      <GuestGuard isAuthenticated={isAuthenticated}>
                        <NewVacancy />
                      </GuestGuard>
                    }
                  />
                </Route>

                <Route path="labor-protection">
                  <Route index element={<LaborProtection />} />
                  <Route
                    path="new"
                    element={
                      <GuestGuard isAuthenticated={isAuthenticated}>
                        <AddSout />
                      </GuestGuard>
                    }
                  />
                  <Route
                    path=":soutID"
                    element={
                      <GuestGuard isAuthenticated={isAuthenticated}>
                        <EditSout />
                      </GuestGuard>
                    }
                  />
                </Route>

                <Route
                  path="write-notice"
                  element={
                    <GuestGuard isAuthenticated={isAuthenticated}>
                      <WriteNotice />
                    </GuestGuard>
                  }
                />
                <Route
                  path="login"
                  element={
                    isAuthenticated ? (
                      <GuestGuard isAuthenticated={isAuthenticated}>
                        <Settings />
                      </GuestGuard>
                    ) : (
                      <Login />
                    )
                  }
                />
                {process.env.REACT_APP_REGISTER === "ON" && (
                  <Route path="register" element={<Register />} />
                )}
                <Route
                  path="settings"
                  element={
                    <GuestGuard isAuthenticated={isAuthenticated}>
                      <Settings />
                    </GuestGuard>
                  }
                />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </div>
          </Suspense>

          <SnowflakesToggle
            showSnowflakes={showSnowflakes}
            handleSnowflakeToggle={handleSnowflakeToggle}
            setShowSnowflakes={setShowSnowflakes}
            isSnowflakeSeason={isSnowflakeSeason}
          />
          <ScrollToTopButton />
          <footer className={style.footer}>
            <Footer />
          </footer>
        </div>
      </div>
      <CookiesDisclaimer />
      <NotificationContainer />
    </>
  );
}

export default App;
