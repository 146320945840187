import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import style from "./CookiesDisclaimer.module.scss";
import Cookies from "js-cookie";
import { Context } from "../../context/Context";

const CookiesDisclaimer = () => {
  const { contrast } = useContext(Context);

  const [isVisible, setIsVisible] = useState(!Cookies.get("cookies_accepted"));

  useEffect(() => {
    setIsVisible(!Cookies.get("cookies_accepted"));
  }, []);

  const handleOk = () => {
    Cookies.set("cookies_accepted", "true", { expires: 7 });
    setIsVisible(false);
  };

  return (
    <div
      className={`${style.cookiesDisclaimerWrapper} ${
        isVisible ? style.visible : ""
      } ${
        contrast === "default"
          ? style.contrastDefault
          : contrast === "light"
          ? style.contrastWhite
          : contrast === "dark"
          ? style.contrastDark
          : contrast === "blue"
          ? style.contrastBlue
          : ""
      }`}
      aria-live="assertive"
    >
      <div className={style.cookiesDisclaimer}>
        <p>
          🍪 Сайт использует{" "}
          <Link to="cookies" aria-label="cookies">
            <b>cookies</b>
          </Link>
          . Продолжая пользоваться сайтом, Вы соглашаетесь с их использованием.
        </p>
        <button
          onClick={handleOk}
          className={style.accept}
          aria-label="Принять"
        >
          Принять
        </button>
      </div>
    </div>
  );
};

export default CookiesDisclaimer;
