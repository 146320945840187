export const LoginStart = (userCredentials) => ({
  type: "LOGIN_START",
});

export const LoginSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
});

export const LoginFailure = () => ({
  type: "LOGIN_FAILURE",
});

export const Logout = () => ({
  type: "LOGOUT",
});

export const PublishStart = (user) => ({
  type: "PUBLISH_START",
  payload: user,
});

export const PublishSuccess = (user) => ({
  type: "PUBLISH_SUCCESS",
  payload: user,
});

export const PublishFailure = (user) => ({
  type: "PUBLISH_FAILURE",
  payload: user,
});

export const DeleteStart = (user) => ({
  type: "DELETE_START",
  payload: user,
});

export const DeleteSuccess = (user) => ({
  type: "DELETE_SUCCESS",
  payload: user,
});

export const DeleteFailure = (user) => ({
  type: "DELETE_FAILURE",
  payload: user,
});

export const FetchStart = (user) => ({
  type: "FETCH_START",
  payload: user,
});

export const FetchSuccess = (user) => ({
  type: "FETCH_SUCCESS",
  payload: user,
});

export const FetchFailure = (user) => ({
  type: "FETCH_FAILURE",
  payload: user,
});

export const EnableAccessibleMode = () => ({
  type: "ENABLE_ACCESSIBLE_MODE",
});

export const DisableAccessibleMode = () => ({
  type: "DISABLE_ACCESSIBLE_MODE",
});

export const SetFontSize = (size) => ({
  type: "SET_FONT_MULTIPLIER",
  payload: size,
});

export const SetContrast = (contrast) => ({
  type: "SET_CONTRAST",
  payload: contrast,
});

export const SetLetterSpacing = (spacing) => ({
  type: "SET_LETTER_SPACING",
  payload: spacing,
});

export const SetTypeface = (typeface) => ({
  type: "SET_TYPEFACE",
  payload: typeface,
});

export const ToggleImages = (mode) => ({
  type: "SET_IMAGE_MODE",
  payload: mode,
});

export const ToggleAnimations = (enabled) => ({
  type: "TOGGLE_ANIMATIONS",
  payload: enabled,
});
